:root {
  /* A4 width */
  --KYCLetterPage-width: 595px;
  --KYCLetterPage-padding-x: calc(var(--KYCLetterPage-width) * 0.05);
  --KYCLetterPage-padding-y: calc(var(--KYCLetterPage-width) * 0.2);
}

.KYCLetterPage {
  width: var(--KYCLetterPage-width);
  font-size: 18px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: var(--KYCLetterPage-padding-y) var(--KYCLetterPage-padding-x);
}