/* Variables */

:root {
  --xcalibra: #06ebb3;
  --sidebar-width: 300px;
  --sidebar-gap: 15px;
}

/* Global */

html,
body {
  padding: 0;
  margin: 0;
}

#root {
  min-height: 100vh;
}

/* Bootstrap overrides */

.navbar {
  /* Restore thin navbar, from original bootstrap */
  padding: 0.5rem 1rem;
}

/* Flat forms, like inline, except no exceptions */

.always-form-inline label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
}
.always-form-inline .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}
.always-form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}
.always-form-inline .form-control-plaintext {
  display: inline-block;
}
.always-form-inline .input-group,
.always-form-inline .custom-select {
  width: auto;
}
.always-form-inline .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: auto;
  padding-left: 0;
}
.always-form-inline .form-check-input {
  position: relative;
  margin-top: 0;
  margin-right: 0.25rem;
  margin-left: 0;
}
.always-form-inline .custom-control {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.always-form-inline .custom-control-label {
  margin-bottom: 0;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}

.hr-text:before {
  content: '';
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

/* Make toasts not overflow */
.Toastify__toast-body {
  word-break: break-word;
}
