.ExternalResourceLink {
  color: var(--gray-dark);
  padding: 4px 9px 2px 2px; /* The thinner top balances it visually better */
}
.ExternalResourceLink-nowrap {
  white-space: nowrap;
}
.ExternalResourceLink > svg + span {
  /* Put spacing between image and label */
  margin-left: 0.25rem;
}

span.ExternalResourceLink {
  cursor: default;
}

a.ExternalResourceLink:hover,
a.ExternalResourceLink:active {
  text-decoration: underline;
  color: var(--success);
}
a.ExternalResourceLink:hover:after,
a.ExternalResourceLink:active:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 0;
  border-color: transparent var(--success) transparent transparent;
  position: absolute;
  margin-left: 1px;
}

.ExternalResourceLink + .ExternalResourceLink {
  margin-left: 3px;
}
