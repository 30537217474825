.Spinner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  width: 30px;
  height: 30px;
  grid-column-gap: 2%;
  grid-row-gap: 2%;
  align-self: center;
  justify-self: center;
}
.Spinner > div {
  background: transparent;
  animation: SpinnerColor 2s infinite alternate;
}
.Spinner > div:nth-child(1) {
  animation-delay: 0.2000004s;
}
.Spinner > div:nth-child(2) {
  animation-delay: 0.1000001s;
}
.Spinner > div:nth-child(4) {
  animation-delay: 0.3000004s;
}
.Spinner > div:nth-child(5) {
  grid-column: 2 / span 2;
  animation-delay: 0.7000001s;
}
.Spinner > div:nth-child(6) {
  clear: both;
  animation-delay: 0.4000004s;
}
.Spinner > div:nth-child(7) {
  animation-delay: 0.5000001s;
}
.Spinner > div:nth-child(8) {
  animation-delay: 0.6000004s;
}


@keyframes SpinnerColor {
  0% {
    background: #06ebb3;
  }
  25% {
    background: #05ae84;
  }
  50% {
    background: #06c697;
  }
}