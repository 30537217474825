.GridLayout {
  display: grid;
}
.GridLayout-auto-cols {
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}
.GridLayout-auto-rows {
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
}

@media (max-width: 767.98px) {
  .GridLayout-responsive {
    display: block;
  }
}
