.Card {
  margin-bottom: 20px;
  width: 100%;
}

.Card-danger > .card > .card-header {
  background: var(--danger);
  color: white;
}

@media (min-width: 576px) {
  .Card {
    display: inline-block;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    margin-right: 20px;
  }
}