.CheckboxGroup {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}

.CheckboxGroup-sub {
  width: fit-content;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.CheckboxGroup-sub > div {
  margin-right: 40px;
}
.CheckboxGroup-sub label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 768px) {
  .CheckboxGroup-sub {
    max-height: 700px;
  }

  .CheckboxGroup-sub > div {
    width: 320px;
  }
}

@media only screen and (min-width: 1084px) {
  .CheckboxGroup-sub {
    max-height: 480px;
  }

  .CheckboxGroup-sub > div {
    width: 320px;
  }
}

@media only screen and (min-width: 1224px) {
  .CheckboxGroup-sub {
    max-height: 420px;
  }

  .CheckboxGroup-sub > div {
    width: 320px;
  }
}

@media only screen and (min-width: 1424px) {
  .CheckboxGroup-sub {
    max-height: 340px;
  }

  .CheckboxGroup-sub > div {
    width: 320px;
  }
}

@media only screen and (min-width: 1824px) {
  .CheckboxGroup-sub {
    max-height: 300px;
  }

  .CheckboxGroup-sub > div {
    width: 320px;
  }
}

@media only screen and (min-width: 1924px) {
  .CheckboxGroup-sub {
    max-height: 280px;
  }
}
