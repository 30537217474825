:root {
  --ExpanderGroup-color: #468074;
}

.ExpanderGroup {
  border-top: 1px solid var(--ExpanderGroup-color);
  padding: 15px 20px 30px 20px;
}

.ExpanderGroup > legend {
  font-size: 1.1em;
  color: var(--ExpanderGroup-color);
  text-align: left;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
  cursor: default;
}

.ExpanderGroup-content {
  margin: -10px;
}
