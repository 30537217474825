.ExchangeInternalsPage-audit-result {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-top: 1.25rem;
}
.ExchangeInternalsPage-audit-result > *:not(:first-child) {
  margin-left: 30px;
}
