.MailerInternalsPage textarea {
  font-family: var(--font-family-monospace);
  height: 200px;
}

@media (min-width: 481px) {
  .MailerInternalsPage-send-form {
    min-width: 480px;
  }
}

@media (min-width: 769px) {
  .MailerInternalsPage-send-form {
    min-width: 768px;
  }
}
