.AjaxWrapper-waiting {
  margin: 10vmin;
  display: flex;
  justify-content: center;
}
.AjaxWrapper-waiting > .Spinner {
  width: 30vmin;
  height: 30vmin;
}
.AjaxWrapper-error {
  margin: 20px;
  display: flex;
  justify-content: center;
}
.AjaxWrapper-error > * {
  max-width: var(--breakpoint-md);
}
