/* Shared */

.ErrorBox {
  max-width: 1199.98px;
}

.ErrorBox-code {
  color: var(--danger);
  background: white;
  font-weight: bold;
}

/* Small */

.ErrorBox-small > .alert {
  margin: 0;
}

.ErrorBox-small > div {
  padding-left: 50px;
}

.ErrorBox-small ul {
  margin: 0;
}

.ErrorBox-small .ErrorBox-title {
  font-size: 16px;
}

.ErrorBox-small .ErrorBox-code {
  position: absolute;
  width: 30px;
  margin-left: -40px;
  font-size: 12px;
  text-align: center;
  padding: 4px 6px;
  border-radius: 2px;
  white-space: nowrap;
  word-break: normal;
}

/* Normal */

.ErrorBox-normal > div {
  padding-left: 60px;
}

.ErrorBox-normal ul {
  margin: 0;
}

.ErrorBox-normal .ErrorBox-title {
  font-size: 20px;
}

.ErrorBox-normal .ErrorBox-code {
  position: absolute;
  width: 40px;
  margin-left: -50px;
  font-size: 15px;
  text-align: center;
  padding: 4px 6px;
  border-radius: 2px;
}

/* Large */

.ErrorBox-large .ErrorBox-code {
  font-size: 20px;
  border-radius: 4px;
  text-align: center;
  padding: 6px;
}

.ErrorBox-large .ErrorBox-title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}
