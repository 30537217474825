.MainMenuSubmenu .dropdown-item {
  padding: 0;
}

.MainMenuSubmenu .dropdown-item > a {
  padding: 0.25rem 1.5rem;
  color: #000;
}

.MainMenuSubmenu .dropdown-item:hover > a {
  color: #fff;
  text-decoration: none;
}

.MainMenuSubmenu .dropdown-menu {
  min-width: 8rem;
  top: -5px !important;
}

.MainMenuSubmenu .MainMenuSubmenu-open > a {
  color: var(--xcalibra) !important;
}

.MainMenuSubmenu .MainMenuSubmenu-active > a {
  color: #18bc9c !important;
}
