.DataTable {
  width: initial;
  border: none;
  position: relative;
  z-index: 0;
}
.DataTable thead,
.DataTable tbody,
.DataTable tr,
.DataTable th,
.DataTable td {
  position: relative !important;
}

.DataTable-sorting-pip {
  color: #999;
  position: absolute;
  right: 0;
  width: 15px;
}

.DataTable th {
  padding-left: 5px;
  padding-right: 20px;
  position: relative;
}

.DataTable th a {
  color: #333;
}
.DataTable th a:hover,
.DataTable th a:hover .DataTable-sorting-pip {
  color: var(--green);
}

.DataTable-th-current {
  background-color: var(--green) !important;
}
.DataTable-th-current a,
.DataTable-th-current .DataTable-sorting-pip {
  color: white !important;
}

.DataTable-th-unsortable {
  color: #666;
  cursor: default;
  padding-right: 5px !important;
}

.DataTable-no-data {
  font-size: 30px;
  padding: 50px !important;
  color: rgba(0,0,0,0.5);
  text-align: center;
}

.DataTable-no-data-reason {
  font-size: 20px;
  color: rgba(0,0,0,0.3);
}

/* Selection */

.DataTable-selectable tbody tr:hover {
  background: rgba(0,0,0,0.05);
  cursor: default;
}
.DataTable-selectable tbody tr.DataTable-row-selected {
  background: #cfe6e0;
}
.DataTable-selectable tbody tr.DataTable-row-selected:hover {
  background: #add9cd;
}
.DataTable-selectable tbody tr.DataTable-row-selected td {
  border-color: #b8d9cf;
}

/* Groups */

:root {
  --DataTable-group-title: #797979;
  --DataTable-group-bg: #e5e5e5;
  --DataTable-group-edge: #d9d9d9;
}

.DataTable-group-headers {
}
.DataTable-group-th-empty,
.DataTable-group-th {
  padding: 0 10px !important;
  z-index: 1;
}
.DataTable-group-th-empty {
  border: none !important;
}
.DataTable-group-th {
  border-left: 2px solid var(--DataTable-group-edge) !important;
  border-right: 2px solid var(--DataTable-group-edge) !important;
  border-top: 2px solid var(--DataTable-group-edge) !important;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  background-color: var(--DataTable-group-bg);
  color: var(--DataTable-group-title);
  cursor: default;
}

.DataTable-group-td-start,
.DataTable-group-th-start {
  border-left: 2px solid var(--DataTable-group-edge) !important;
}
.DataTable-group-td-end,
.DataTable-group-th-end {
  border-right: 2px solid var(--DataTable-group-edge) !important;
}

/* Highlighting */

.DataTable-highlight {
  background-color: rgba(232, 245, 252, 0.6);
}
.DataTable-row-selected .DataTable-highlight {
  background-color: rgba(212, 235, 250, 0.6);
}
.DataTable tbody tr:hover .DataTable-highlight {
  background-color: rgba(225, 240, 250, 0.6);
}
.DataTable tbody tr.DataTable-row-selected:hover .DataTable-highlight {
  background-color: rgba(206, 230, 245, 0.6);
}
.DataTable-group-th.DataTable-highlight {
  background-color: rgba(218, 225, 230, 0.6);
}