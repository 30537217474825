.SelectionSidebar {
  width: var(--sidebar-width);
}

.SelectionSidebar-content {
  position: sticky;
  top: 15px;
  width: var(--sidebar-width);
  margin-bottom: 1rem;
}

.SelectionSidebar-no-data {
  border: 2px dotted rgba(0, 0, 0, 0.1);
  padding: 2rem 1.5rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 1.171875rem;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 15px;
}
