.KYCLevelIcon {
  display: inline-block;
  position: relative;
  font-size: inherit;
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}
.KYCLevelIcon > span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  box-shadow: inset -0.02em -0.02em 0.05em 0.01em rgba(0, 0, 0, 0.2);
  font-family: var(--font-family-monospace);
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.7em;
}
.KYCLevelIcon-level-0 > span {
  background: hsl(343, 55%, 71%);
  color: white;
}
.KYCLevelIcon-level-1 > span {
  background: hsl(30, 55%, 70%);
}
.KYCLevelIcon-level-2 > span {
  background: hsl(193, 55%, 75%);
}
.KYCLevelIcon-level-3 > span {
  background: hsl(51, 70%, 70%);
}
