.MarketInternalsPage-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.MarketInternalsPage-market {
  border: 1px solid #95a5a6;
  border-radius: 4px;
  overflow: hidden;
}
.MarketInternalsPage-market + .MarketInternalsPage-market {
  margin-top: 20px;
}

.MarketInternalsPage-market-header {
  /*background: linear-gradient(to bottom, rgba(0,0,0,0.08), transparent);*/
  background: #ebeff0;
  padding: 10px;
  min-width: 120px;
  display: grid;
  grid-template-rows: 1fr auto;
}
.MarketInternalsPage-market-header h4 {
  text-align: center;
  color: #4a5d60;
  margin: 0;
}
.MarketInternalsPage-market-controls {
  margin-top: 5px;
  text-align: center;
}
.MarketInternalsPage-market-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  padding: 15px;
}

.MarketInternalsPage-market-col {
  min-width: 200px;
}

@media (min-width: 768px) {
  .MarketInternalsPage-market {
    display: grid;
    grid-template-columns: auto 1fr auto;
  }
}

@media (min-width: 576px) {
  .MarketInternalsPage-market-col:not(:last-child) {
    margin-right: 15px;
  }
}
