.CriticalityIndicator {
  height: 4px;
  border: none;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.4), 0 2px 2px rgba(0,0,0,0.5);
}
.CriticalityIndicator-green {
  background-color: var(--green);
}
.CriticalityIndicator-yellow {
  background-color: var(--yellow);
}
.CriticalityIndicator-red {
  background-color: var(--red);
}