@media (max-width: 767.98px) {
  .MicropaymentEditPage-reference-and-customer .btn {
    display: block;
    width: 100%;
  }
  .MicropaymentEditPage-reference-and-customer svg {
    display: none;
  }

  .MicropaymentEditPage-documents .text-nowrap {
    white-space: normal !important;
  }
}
