.NestedExpandableObjectInfo > div > .Expander-content {
  padding: 0;
}
.NestedExpandableObjectInfo-item > div > .Expander-content {
  border: none;
  background: none;
}
.NestedExpandableObjectInfo-title {
  background: rgba(185, 198, 201, 0.2);
  color: #468074;
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: auto 1fr;
  padding: 3px 3px 3px 5px;
  cursor: pointer;
  transition: all 0.1s;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  user-select: none;
}
.NestedExpandableObjectInfo-title:hover {
  background: rgba(185, 198, 201, 0.1);
}

.NestedExpandableObjectInfo > div > .Expander-content > .Expander {
  margin: 0;
}
