.MainMenu-navigation {
  margin-left: 20px;
}

.MainMenu-navigation button {
  padding: 0;
}

.MainMenu-navigation div button:nth-child(2) {
  padding: 0 5px;
}

.MainMenu-navigation button:hover,
.MainMenu-navigation button:hover * {
  color: var(--xcalibra) !important;
}

.MainMenu-user {
  margin-left: auto;
  position: sticky;
  right: 10px;
}

:not(:hover) .MainMenu-active-submenu-item {
  background: var(--success);
  color: white !important;
}

/* Based on how big it actually is */
@media (max-width: 991px) and (min-width: 768px) {
  .MainMenu-item-title {
    display: none;
  }
}

@media (max-width: 1150px) and (min-width: 768px) {
  .MainMenu-user .MainMenu-item-title {
    display: none;
  }
}
