.CustomersPage-labeled-badges {
  font-weight: bold;
}
.CustomersPage-strong-em {
  font-weight: bold;
  font-style: italic;
}

.CustomersPage-audit-modal {
}
