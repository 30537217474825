.Select {
  z-index: 3;
}
.Select__control {
  color: #7b8a8b;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.Select__control--is-focused {
  color: #7b8a8b;
  background-color: #fff;
  border-color: #597ea2 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25) !important;
}
.Select__option--is-focused {
  background-color: #cfe6e0 !important;
}
.Select__option--is-selected {
  background-color: var(--green) !important;
}
.Select__option.Select__option--is-selected span {
  /* Make sure we override stuff like text-success */
  color: white !important;
}
.Select__option--is-focused.Select__option--is-selected {
  background-color: #19b391 !important;
}
.Select__clear-indicator {
  cursor: pointer;
}
