.DevIconsPage-content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 110px);
}
.DevIconsPage-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}
.DevIconsPage-icon svg {
  font-size: 40px;
  margin: 5px;
}
.DevIconsPage-icon h5 {
  margin: 10px 0 0 0;
  font-size: 15px;
  word-break: break-word;
}
.DevIconsPage-icon h6 {
  font-family: var(--font-family-monospace);
  margin: 0;
  font-size: 12px;
}
.DevIconsPage-icon a {
  color: var(--primary);
}
