.HomePage-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.HomePage-link:hover {
  text-decoration: none;
}
.HomePage-link h6 {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 992px) {
  .HomePage-section {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .HomePage-section {
    display: block;
  }
  .HomePage-link {
    display: block;
  }
  .HomePage-link + .HomePage-link {
    margin-top: 10px;
  }
}
