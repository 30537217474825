.SettingsRegistrationPage form > * {
  margin: 10px 20px;
}

.SettingsRegistrationPage form > div > label {
  font-weight: 700;
}

.SettingsRegistrationPage textarea {
  max-width: 600px;
}
