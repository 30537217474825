:root {
  --ConfigDisabledEditor-transition: all 0.3s;
}

.ConfigDisabledEditor-holder {
  background: white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: var(--ConfigDisabledEditor-transition);
}

.ConfigDisabledEditor-holder .ThreeStateCheckbox {
  margin: 2px 4px;
}

.ConfigDisabledEditor-reason {
  transition: var(--ConfigDisabledEditor-transition);
  position: relative;
}

.ConfigDisabledEditor-reason textarea {
  border: none;
  border-radius: 0 0 0.25rem 0.25rem;
  position: relative;
  height: 100%;
  background: transparent;
  overflow: hidden;
}

.ConfigDisabledEditor-reason-label {
  position: absolute;
  right: -1px;
  bottom: -1px;
  z-index: 1;
  border: 1px solid #ced4da;
  background: var(--light);
  padding: 4px 8px;
  color: var(--dark);
  border-radius: 0.25rem 0 0.25rem 0;
  opacity: 1;
  transition: var(--ConfigDisabledEditor-transition);
  pointer-events: none;
}

/* Enabled state, hide stuff */

.ConfigDisabledEditor-enabled .ConfigDisabledEditor-holder {
  background: transparent;
  border-color: transparent;
  overflow: hidden;
}

.ConfigDisabledEditor-enabled .ConfigDisabledEditor-reason {
  height: 0;
  overflow: hidden;
}

.ConfigDisabledEditor-enabled .ConfigDisabledEditor-reason-label {
  opacity: 0;
}

.ConfigDisabledEditor-reason textarea:focus + .ConfigDisabledEditor-reason-label {
  opacity: 0.3;
}