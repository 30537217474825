:root {
  --SubPager-bg: #b9c6c9;
  --SubPager-tab-bg: #dce1e1;
  --SubPager-tab-fg: #788487;
  --SubPager-tab-hover-fg: #454c4e;
  --SubPager-tab-hover-bg: #f3f8f8;
  --SubPager-tab-active-bg: white;
  --SubPager-tab-active-fg: var(--primary);
  --SubPager-tab-height: 30px;
}

.SubPager {
  background-color: var(--SubPager-bg);
  padding: 10px 15px 0 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.SubPager-tab {
  position: relative;
  margin-right: -5px;
}
.SubPager-tab + .SubPager-tab {
  margin-left: 20px;
}

.SubPager-tab a {
  display: inline-block;
  background-color: var(--SubPager-tab-bg);
  color: var(--SubPager-tab-fg);
  padding: 8px 10px 0 10px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  height: var(--SubPager-tab-height);
  white-space: nowrap;
  transition: all 0.1s;
}
.SubPager-tab a span {
  display: inline-block;
}

.SubPager-tab a::before {
  content: '';
  position: absolute;
  margin-left: -20px;
  top: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 var(--SubPager-tab-height) 10px;
  border-color: transparent transparent var(--SubPager-tab-bg) transparent;
  transition: all 0.1s;
}
.SubPager-tab a::after {
  content: '';
  position: absolute;
  margin-left: 10px;
  top: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: var(--SubPager-tab-height) 0 0 10px;
  border-color: transparent transparent transparent var(--SubPager-tab-bg);
  transition: all 0.1s;
}

.SubPager-tab-active {
  z-index: 10;
  pointer-events: none;
}
.SubPager-tab-active a {
  color: var(--SubPager-tab-active-fg);
  background-color: var(--SubPager-tab-active-bg);
}
.SubPager-tab-active a::before {
  border-color: transparent transparent var(--SubPager-tab-active-bg) transparent;
}
.SubPager-tab-active a::after {
  border-color: transparent transparent transparent var(--SubPager-tab-active-bg);
}

.SubPager-tab a:hover {
  text-decoration: none;
  background-color: var(--SubPager-tab-hover-bg);
  color: var(--SubPager-tab-hover-fg);
}
.SubPager-tab a:hover::before {
  border-color: transparent transparent var(--SubPager-tab-hover-bg) transparent;
}
.SubPager-tab a:hover::after {
  border-color: transparent transparent transparent var(--SubPager-tab-hover-bg);
}