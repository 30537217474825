.BoundMicropaymentAuditModal {
}

.BoundMicropaymentAuditModal-content {
  border-bottom: 1px solid var(--ExpanderGroup-color);
}
.BoundMicropaymentAuditModal-content .ExpanderGroup {
  position: relative;
  padding-bottom: 10px;
}

.BoundMicropaymentAuditModal-auditor-notes {
  margin: 1rem 0.5rem 0 0.5rem;
}

@media (min-width: 992px) {
  .BoundMicropaymentAuditModal-content .ExpanderGroup:first-child:after {
    content: '';
    position: absolute;
    right: 0;
    top: 11px;
    bottom: 0;
    width: 1px;
    border-right: 1px solid var(--ExpanderGroup-color);
  }
}

@media (max-width: 991.98px) {
  .BoundMicropaymentAuditModal-content {
    display: block;
  }
}
