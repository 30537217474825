.ObjectInfo-prop {
  margin-bottom: 10px;
}

.ObjectInfo-key {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  line-height: 16px;
}
.ObjectInfo-key > span {
  font-size: 11px;
  line-height: 11px;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  padding: 2px 10px;
  border-radius: 2px 2px 0 0;
}

.ObjectInfo-value {
  padding-left: 10px;
  word-break: break-all;
}

/* Nesting support */

.ObjectInfo .ObjectInfo {
  margin-top: 5px;
}
.ObjectInfo .ObjectInfo .ObjectInfo-key {
  border: none;
}
.ObjectInfo .ObjectInfo .ObjectInfo-key > span {
  font-size: 10px;
  background: transparent;
  padding: 0;
  border-radius: 0;
}
