@import url(https://fonts.googleapis.com/css?family=Lato:400,700,400italic);
/*!
 * Bootswatch v4.1.3
 * Homepage: https://bootswatch.com
 * Copyright 2012-2018 Thomas Park
 * Licensed under MIT
 * Based on Bootstrap
*/
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #2C3E50;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #E74C3C;
  --orange: #fd7e14;
  --yellow: #F39C12;
  --green: #18BC9C;
  --teal: #20c997;
  --cyan: #3498DB;
  --white: #fff;
  --gray: #95a5a6;
  --gray-dark: #343a40;
  --primary: #2C3E50;
  --secondary: #95a5a6;
  --success: #18BC9C;
  --info: #3498DB;
  --warning: #F39C12;
  --danger: rgb(231, 76, 60);
  --light: #ecf0f1;
  --dark: #7b8a8b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #18BC9C;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0f7864;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #95a5a6;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 3rem;
}

h2, .h2 {
  font-size: 2.5rem;
}

h3, .h3 {
  font-size: 2rem;
}

h4, .h4 {
  font-size: 1.40625rem;
}

h5, .h5 {
  font-size: 1.171875rem;
}

h6, .h6 {
  font-size: 0.9375rem;
}

.lead {
  font-size: 1.171875rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.171875rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #95a5a6;
}

.blockquote-footer::before {
  content: "\2014   \A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #95a5a6;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ecf0f1;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c4c9ce;
}

.table-hover .table-primary:hover {
  background-color: #b6bcc2;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b6bcc2;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e1e6e6;
}

.table-hover .table-secondary:hover {
  background-color: #d3dada;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #d3dada;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #beece3;
}

.table-hover .table-success:hover {
  background-color: #aae6db;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #aae6db;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c6e2f5;
}

.table-hover .table-info:hover {
  background-color: #b0d7f1;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b0d7f1;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fce3bd;
}

.table-hover .table-warning:hover {
  background-color: #fbd9a5;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbd9a5;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8cdc8;
}

.table-hover .table-danger:hover {
  background-color: #f5b8b1;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f5b8b1;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fafbfb;
}

.table-hover .table-light:hover {
  background-color: #ecf0f0;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ecf0f0;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #dadedf;
}

.table-hover .table-dark:hover {
  background-color: #ccd2d3;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #ccd2d3;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #7b8a8b;
  background-color: #ecf0f1;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.15625rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  color: #7b8a8b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #7b8a8b;
  background-color: #fff;
  border-color: #597ea2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #95a5a6;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #95a5a6;
  opacity: 1;
}

.form-control::placeholder {
  color: #95a5a6;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #ecf0f1;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #7b8a8b;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.171875rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.8203125rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.73046875rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.8203125rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.7578125rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.171875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #95a5a6;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #18BC9C;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.8203125rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(24, 188, 156, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #18BC9C;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #18BC9C;
  box-shadow: 0 0 0 0.2rem rgba(24, 188, 156, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #18BC9C;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #18BC9C;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #68ecd2;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #24e3be;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(24, 188, 156, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #18BC9C;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(24, 188, 156, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #E74C3C;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.8203125rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(231, 76, 60, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #E74C3C;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #E74C3C;
  box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #E74C3C;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #E74C3C;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #f5b4ae;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ed7669;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(231, 76, 60, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #E74C3C;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #2C3E50;
  border-color: #2C3E50;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1e2b37;
  border-color: #1a252f;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #2C3E50;
  border-color: #2C3E50;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1a252f;
  border-color: #151e27;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #95a5a6;
  border-color: #95a5a6;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #809395;
  border-color: #798d8f;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 165, 166, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #95a5a6;
  border-color: #95a5a6;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #798d8f;
  border-color: #738789;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 165, 166, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #18BC9C;
  border-color: #18BC9C;
}

.btn-success:hover {
  color: #fff;
  background-color: #149a80;
  border-color: #128f76;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 188, 156, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #18BC9C;
  border-color: #18BC9C;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #128f76;
  border-color: #11836d;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 188, 156, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #3498DB;
  border-color: #3498DB;
}

.btn-info:hover {
  color: #fff;
  background-color: #2384c6;
  border-color: #217dbb;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #3498DB;
  border-color: #3498DB;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #217dbb;
  border-color: #1f76b0;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #F39C12;
  border-color: #F39C12;
}

.btn-warning:hover {
  color: #fff;
  background-color: #d4860b;
  border-color: #c87f0a;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #F39C12;
  border-color: #F39C12;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #c87f0a;
  border-color: #bc770a;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #E74C3C;
  border-color: #E74C3C;
}

.btn-danger:hover {
  color: #fff;
  background-color: #e12e1c;
  border-color: #d62c1a;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #E74C3C;
  border-color: #E74C3C;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d62c1a;
  border-color: #ca2a19;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #ecf0f1;
  border-color: #ecf0f1;
}

.btn-light:hover {
  color: #212529;
  background-color: #d6dfe1;
  border-color: #cfd9db;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 240, 241, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #ecf0f1;
  border-color: #ecf0f1;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #cfd9db;
  border-color: #c7d3d6;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 240, 241, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #7b8a8b;
  border-color: #7b8a8b;
}

.btn-dark:hover {
  color: #fff;
  background-color: #697677;
  border-color: #636f70;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 138, 139, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #7b8a8b;
  border-color: #7b8a8b;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #636f70;
  border-color: #5d696a;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 138, 139, 0.5);
}

.btn-outline-primary {
  color: #2C3E50;
  background-color: transparent;
  background-image: none;
  border-color: #2C3E50;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #2C3E50;
  border-color: #2C3E50;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #2C3E50;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2C3E50;
  border-color: #2C3E50;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.5);
}

.btn-outline-secondary {
  color: #95a5a6;
  background-color: transparent;
  background-image: none;
  border-color: #95a5a6;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #95a5a6;
  border-color: #95a5a6;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 165, 166, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #95a5a6;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #95a5a6;
  border-color: #95a5a6;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(149, 165, 166, 0.5);
}

.btn-outline-success {
  color: #18BC9C;
  background-color: transparent;
  background-image: none;
  border-color: #18BC9C;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #18BC9C;
  border-color: #18BC9C;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 188, 156, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #18BC9C;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #18BC9C;
  border-color: #18BC9C;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 188, 156, 0.5);
}

.btn-outline-info {
  color: #3498DB;
  background-color: transparent;
  background-image: none;
  border-color: #3498DB;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #3498DB;
  border-color: #3498DB;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #3498DB;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #3498DB;
  border-color: #3498DB;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.5);
}

.btn-outline-warning {
  color: #F39C12;
  background-color: transparent;
  background-image: none;
  border-color: #F39C12;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #F39C12;
  border-color: #F39C12;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #F39C12;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #F39C12;
  border-color: #F39C12;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5);
}

.btn-outline-danger {
  color: #E74C3C;
  background-color: transparent;
  background-image: none;
  border-color: #E74C3C;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #E74C3C;
  border-color: #E74C3C;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #E74C3C;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #E74C3C;
  border-color: #E74C3C;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5);
}

.btn-outline-light {
  color: #ecf0f1;
  background-color: transparent;
  background-image: none;
  border-color: #ecf0f1;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #ecf0f1;
  border-color: #ecf0f1;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 240, 241, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #ecf0f1;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #ecf0f1;
  border-color: #ecf0f1;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 240, 241, 0.5);
}

.btn-outline-dark {
  color: #7b8a8b;
  background-color: transparent;
  background-image: none;
  border-color: #7b8a8b;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #7b8a8b;
  border-color: #7b8a8b;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 138, 139, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #7b8a8b;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #7b8a8b;
  border-color: #7b8a8b;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 138, 139, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #18BC9C;
  background-color: transparent;
}

.btn-link:hover {
  color: #0f7864;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #95a5a6;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.171875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.8203125rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9375rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #ecf0f1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #7b8a8b;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: #2C3E50;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #2C3E50;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #95a5a6;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.8203125rem;
  color: #95a5a6;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #7b8a8b;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #7b8a8b;
  text-align: center;
  white-space: nowrap;
  background-color: #ecf0f1;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.7578125rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.171875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.73046875rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.8203125rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.40625rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #2C3E50;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #7997b5;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #95a5a6;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #ecf0f1;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.203125rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.203125rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2C3E50;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #2C3E50;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(44, 62, 80, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(44, 62, 80, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2C3E50;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(44, 62, 80, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.15625rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #7b8a8b;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #597ea2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(89, 126, 162, 0.5);
}

.custom-select:focus::-ms-value {
  color: #7b8a8b;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #95a5a6;
  background-color: #ecf0f1;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.73046875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.7578125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.15625rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.15625rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #597ea2;
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #597ea2;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #ecf0f1;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.15625rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #7b8a8b;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.15625rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #7b8a8b;
  content: "Browse";
  background-color: #ecf0f1;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2C3E50;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #7997b5;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2C3E50;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #7997b5;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #2C3E50;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #7997b5;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 2rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #95a5a6;
}

.nav-tabs {
  border-bottom: 1px solid #ecf0f1;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #ecf0f1 #ecf0f1 #ecf0f1;
}

.nav-tabs .nav-link.disabled {
  color: #95a5a6;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #7b8a8b;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2C3E50;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.32421875rem;
  padding-bottom: 0.32421875rem;
  margin-right: 1rem;
  font-size: 1.171875rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.171875rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #18BC9C;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: #fff;
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #ecf0f1;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #95a5a6;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #95a5a6;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #fff;
  background-color: #18BC9C;
  border: 0 solid transparent;
}

.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #0f7864;
  border-color: transparent;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0f7864;
  border-color: transparent;
}

.page-item.disabled .page-link {
  color: #ecf0f1;
  pointer-events: none;
  cursor: auto;
  background-color: #3be6c4;
  border-color: transparent;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.171875rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8203125rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #2C3E50;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1a252f;
}

.badge-secondary {
  color: #fff;
  background-color: #95a5a6;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #798d8f;
}

.badge-success {
  color: #fff;
  background-color: #18BC9C;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #128f76;
}

.badge-info {
  color: #fff;
  background-color: #3498DB;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #217dbb;
}

.badge-warning {
  color: #fff;
  background-color: #F39C12;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #c87f0a;
}

.badge-danger {
  color: #fff;
  background-color: #E74C3C;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #d62c1a;
}

.badge-light {
  color: #212529;
  background-color: #ecf0f1;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #cfd9db;
}

.badge-dark {
  color: #fff;
  background-color: #7b8a8b;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #636f70;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #ecf0f1;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.90625rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #17202a;
  background-color: #d5d8dc;
  border-color: #c4c9ce;
}

.alert-primary hr {
  border-top-color: #b6bcc2;
}

.alert-primary .alert-link {
  color: #050709;
}

.alert-secondary {
  color: #4d5656;
  background-color: #eaeded;
  border-color: #e1e6e6;
}

.alert-secondary hr {
  border-top-color: #d3dada;
}

.alert-secondary .alert-link {
  color: #353b3b;
}

.alert-success {
  color: #0c6251;
  background-color: #d1f2eb;
  border-color: #beece3;
}

.alert-success hr {
  border-top-color: #aae6db;
}

.alert-success .alert-link {
  color: #06352b;
}

.alert-info {
  color: #1b4f72;
  background-color: #d6eaf8;
  border-color: #c6e2f5;
}

.alert-info hr {
  border-top-color: #b0d7f1;
}

.alert-info .alert-link {
  color: #113249;
}

.alert-warning {
  color: #7e5109;
  background-color: #fdebd0;
  border-color: #fce3bd;
}

.alert-warning hr {
  border-top-color: #fbd9a5;
}

.alert-warning .alert-link {
  color: #4e3206;
}

.alert-danger {
  color: #78281f;
  background-color: #fadbd8;
  border-color: #f8cdc8;
}

.alert-danger hr {
  border-top-color: #f5b8b1;
}

.alert-danger .alert-link {
  color: #4f1a15;
}

.alert-light {
  color: #7b7d7d;
  background-color: #fbfcfc;
  border-color: #fafbfb;
}

.alert-light hr {
  border-top-color: #ecf0f0;
}

.alert-light .alert-link {
  color: #626363;
}

.alert-dark {
  color: #404848;
  background-color: #e5e8e8;
  border-color: #dadedf;
}

.alert-dark hr {
  border-top-color: #ccd2d3;
}

.alert-dark .alert-link {
  color: #282d2d;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0.625rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 0.625rem;
  overflow: hidden;
  font-size: 0.625rem;
  background-color: #ecf0f1;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2C3E50;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.625rem 0.625rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #7b8a8b;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #7b8a8b;
  text-decoration: none;
  background-color: #ecf0f1;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #ecf0f1;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #95a5a6;
  background-color: #ecf0f1;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2C3E50;
  border-color: #2C3E50;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #17202a;
  background-color: #c4c9ce;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #17202a;
  background-color: #b6bcc2;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #17202a;
  border-color: #17202a;
}

.list-group-item-secondary {
  color: #4d5656;
  background-color: #e1e6e6;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #4d5656;
  background-color: #d3dada;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #4d5656;
  border-color: #4d5656;
}

.list-group-item-success {
  color: #0c6251;
  background-color: #beece3;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0c6251;
  background-color: #aae6db;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0c6251;
  border-color: #0c6251;
}

.list-group-item-info {
  color: #1b4f72;
  background-color: #c6e2f5;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #1b4f72;
  background-color: #b0d7f1;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #1b4f72;
  border-color: #1b4f72;
}

.list-group-item-warning {
  color: #7e5109;
  background-color: #fce3bd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7e5109;
  background-color: #fbd9a5;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7e5109;
  border-color: #7e5109;
}

.list-group-item-danger {
  color: #78281f;
  background-color: #f8cdc8;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #78281f;
  background-color: #f5b8b1;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #78281f;
  border-color: #78281f;
}

.list-group-item-light {
  color: #7b7d7d;
  background-color: #fafbfb;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7b7d7d;
  background-color: #ecf0f0;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7b7d7d;
  border-color: #7b7d7d;
}

.list-group-item-dark {
  color: #404848;
  background-color: #dadedf;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #404848;
  background-color: #ccd2d3;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #404848;
  border-color: #404848;
}

.close {
  float: right;
  font-size: 1.40625rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: none;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #fff;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #ecf0f1;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #ecf0f1;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8203125rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8203125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #2C3E50 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1a252f !important;
}

.bg-secondary {
  background-color: #95a5a6 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #798d8f !important;
}

.bg-success {
  background-color: #18BC9C !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #128f76 !important;
}

.bg-info {
  background-color: #3498DB !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #217dbb !important;
}

.bg-warning {
  background-color: #F39C12 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c87f0a !important;
}

.bg-danger {
  background-color: #E74C3C !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d62c1a !important;
}

.bg-light {
  background-color: #ecf0f1 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cfd9db !important;
}

.bg-dark {
  background-color: #7b8a8b !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #636f70 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #2C3E50 !important;
}

.border-secondary {
  border-color: #95a5a6 !important;
}

.border-success {
  border-color: #18BC9C !important;
}

.border-info {
  border-color: #3498DB !important;
}

.border-warning {
  border-color: #F39C12 !important;
}

.border-danger {
  border-color: #E74C3C !important;
}

.border-light {
  border-color: #ecf0f1 !important;
}

.border-dark {
  border-color: #7b8a8b !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #2C3E50 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1a252f !important;
}

.text-secondary {
  color: #95a5a6 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #798d8f !important;
}

.text-success {
  color: #18BC9C !important;
}

a.text-success:hover, a.text-success:focus {
  color: #128f76 !important;
}

.text-info {
  color: #3498DB !important;
}

a.text-info:hover, a.text-info:focus {
  color: #217dbb !important;
}

.text-warning {
  color: #F39C12 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #c87f0a !important;
}

.text-danger {
  color: #E74C3C !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #d62c1a !important;
}

.text-light {
  color: #ecf0f1 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cfd9db !important;
}

.text-dark {
  color: #7b8a8b !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #636f70 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #95a5a6 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #b4bcc2;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.bg-primary .navbar-nav .active > .nav-link {
  color: #18BC9C !important;
}

.bg-dark {
  background-color: #18BC9C !important;
}

.bg-dark.navbar-dark .navbar-nav .nav-link:focus,
.bg-dark.navbar-dark .navbar-nav .nav-link:hover,
.bg-dark.navbar-dark .navbar-nav .active > .nav-link {
  color: #2C3E50 !important;
}

.btn-secondary, .btn-secondary:hover, .btn-warning, .btn-warning:hover {
  color: #fff;
}

.table-primary, .table-secondary, .table-success, .table-info, .table-warning, .table-danger {
  color: #fff;
}

.table-primary, .table-primary > th, .table-primary > td {
  background-color: #2C3E50;
}

.table-secondary, .table-secondary > th, .table-secondary > td {
  background-color: #95a5a6;
}

.table-light, .table-light > th, .table-light > td {
  background-color: #ecf0f1;
}

.table-dark, .table-dark > th, .table-dark > td {
  background-color: #7b8a8b;
}

.table-success, .table-success > th, .table-success > td {
  background-color: #18BC9C;
}

.table-info, .table-info > th, .table-info > td {
  background-color: #3498DB;
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color: #E74C3C;
}

.table-warning, .table-warning > th, .table-warning > td {
  background-color: #F39C12;
}

.table-active, .table-active > th, .table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > th, .table-hover .table-primary:hover > td {
  background-color: #233140;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > th, .table-hover .table-secondary:hover > td {
  background-color: #87999a;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > th, .table-hover .table-light:hover > td {
  background-color: #dde4e6;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > th, .table-hover .table-dark:hover > td {
  background-color: #6f7d7e;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > th, .table-hover .table-success:hover > td {
  background-color: #15a589;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > th, .table-hover .table-info:hover > td {
  background-color: #258cd1;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > th, .table-hover .table-danger:hover > td {
  background-color: #e43725;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > th, .table-hover .table-warning:hover > td {
  background-color: #e08e0b;
}

.table-hover .table-active:hover, .table-hover .table-active:hover > th, .table-hover .table-active:hover > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-item.open .nav-link,
.nav-tabs .nav-item.open .nav-link:focus,
.nav-tabs .nav-item.open .nav-link:hover {
  color: #2C3E50;
}

.pagination a:hover {
  text-decoration: none;
}

.close {
  text-decoration: none;
  opacity: 0.4;
}

.close:hover, .close:focus {
  opacity: 1;
}

.badge-secondary, .badge-warning {
  color: #fff;
}

.alert {
  border: none;
  color: #fff;
}

.alert a,
.alert .alert-link {
  color: #fff;
  text-decoration: underline;
}

.alert-primary {
  background-color: #2C3E50;
}

.alert-secondary {
  background-color: #95a5a6;
}

.alert-success {
  background-color: #18BC9C;
}

.alert-info {
  background-color: #3498DB;
}

.alert-warning {
  background-color: #F39C12;
}

.alert-danger {
  background-color: #E74C3C;
}

.alert-light {
  background-color: #ecf0f1;
}

.alert-dark {
  background-color: #7b8a8b;
}

.alert-light,
.alert-light a,
.alert-light .alert-link {
  color: #212529;
}

.modal .close {
  color: #000;
}

.modal .close:not(:disabled):not(.disabled):hover, .modal .close:not(:disabled):not(.disabled):focus {
  color: #000;
}
.Toastify__toast-container {
  z-index: 9999;
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff; }
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em; }
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  margin-left: -160px; }
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em; }
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em; }
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  margin-left: -160px; }
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em; }

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0; }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0; }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0; }
  .Toastify__toast-container--rtl {
    right: 0;
    left: auto;
    left: initial; } }

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr; }
.Toastify__toast--rtl {
  direction: rtl; }
.Toastify__toast--default {
  background: #fff;
  color: #aaa; }
.Toastify__toast--info {
  background: #3498DB; }
.Toastify__toast--success {
  background: #18BC9C; }
.Toastify__toast--warning {
  background: #F39C12; }
.Toastify__toast--error {
  background: #E74C3C; }
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1; }

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0; } }

.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start; }
.Toastify__close-button--default {
  color: #000;
  opacity: 0.3; }
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1; }

@keyframes Toastify__trackProgress {
  0% {
    width: 100%; }
  100% {
    width: 0; } }

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  animation: Toastify__trackProgress linear 1;
  background-color: rgba(255, 255, 255, 0.7); }
.Toastify__progress-bar--rtl {
  right: 0;
  left: auto;
  left: initial; }
.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown; }

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut; }

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.Toastify__flip-enter {
  animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
  animation-name: Toastify__flipOut; }

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0); } }

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0); } }

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown; }

/*# sourceMappingURL=ReactToastify.css.map */
/* Variables */

:root {
  --xcalibra: #06ebb3;
  --sidebar-width: 300px;
  --sidebar-gap: 15px;
}

/* Global */

html,
body {
  padding: 0;
  margin: 0;
}

#root {
  min-height: 100vh;
}

/* Bootstrap overrides */

.navbar {
  /* Restore thin navbar, from original bootstrap */
  padding: 0.5rem 1rem;
}

/* Flat forms, like inline, except no exceptions */

.always-form-inline label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
.always-form-inline .form-group {
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 0;
}
.always-form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}
.always-form-inline .form-control-plaintext {
  display: inline-block;
}
.always-form-inline .input-group,
.always-form-inline .custom-select {
  width: auto;
}
.always-form-inline .form-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding-left: 0;
}
.always-form-inline .form-check-input {
  position: relative;
  margin-top: 0;
  margin-right: 0.25rem;
  margin-left: 0;
}
.always-form-inline .custom-control {
  align-items: center;
  justify-content: center;
}
.always-form-inline .custom-control-label {
  margin-bottom: 0;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}

.hr-text:before {
  content: '';
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

/* Make toasts not overflow */
.Toastify__toast-body {
  word-break: break-word;
}

.App {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto auto auto 1fr;
}
/* Shared */

.ErrorBox {
  max-width: 1199.98px;
}

.ErrorBox-code {
  color: var(--danger);
  background: white;
  font-weight: bold;
}

/* Small */

.ErrorBox-small > .alert {
  margin: 0;
}

.ErrorBox-small > div {
  padding-left: 50px;
}

.ErrorBox-small ul {
  margin: 0;
}

.ErrorBox-small .ErrorBox-title {
  font-size: 16px;
}

.ErrorBox-small .ErrorBox-code {
  position: absolute;
  width: 30px;
  margin-left: -40px;
  font-size: 12px;
  text-align: center;
  padding: 4px 6px;
  border-radius: 2px;
  white-space: nowrap;
  word-break: normal;
}

/* Normal */

.ErrorBox-normal > div {
  padding-left: 60px;
}

.ErrorBox-normal ul {
  margin: 0;
}

.ErrorBox-normal .ErrorBox-title {
  font-size: 20px;
}

.ErrorBox-normal .ErrorBox-code {
  position: absolute;
  width: 40px;
  margin-left: -50px;
  font-size: 15px;
  text-align: center;
  padding: 4px 6px;
  border-radius: 2px;
}

/* Large */

.ErrorBox-large .ErrorBox-code {
  font-size: 20px;
  border-radius: 4px;
  text-align: center;
  padding: 6px;
}

.ErrorBox-large .ErrorBox-title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

:root {
  --SubPager-bg: #b9c6c9;
  --SubPager-tab-bg: #dce1e1;
  --SubPager-tab-fg: #788487;
  --SubPager-tab-hover-fg: #454c4e;
  --SubPager-tab-hover-bg: #f3f8f8;
  --SubPager-tab-active-bg: white;
  --SubPager-tab-active-fg: var(--primary);
  --SubPager-tab-height: 30px;
}

.SubPager {
  background-color: var(--SubPager-bg);
  padding: 10px 15px 0 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.SubPager-tab {
  position: relative;
  margin-right: -5px;
}
.SubPager-tab + .SubPager-tab {
  margin-left: 20px;
}

.SubPager-tab a {
  display: inline-block;
  background-color: var(--SubPager-tab-bg);
  color: var(--SubPager-tab-fg);
  padding: 8px 10px 0 10px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  height: var(--SubPager-tab-height);
  white-space: nowrap;
  transition: all 0.1s;
}
.SubPager-tab a span {
  display: inline-block;
}

.SubPager-tab a::before {
  content: '';
  position: absolute;
  margin-left: -20px;
  top: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 var(--SubPager-tab-height) 10px;
  border-color: transparent transparent var(--SubPager-tab-bg) transparent;
  transition: all 0.1s;
}
.SubPager-tab a::after {
  content: '';
  position: absolute;
  margin-left: 10px;
  top: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: var(--SubPager-tab-height) 0 0 10px;
  border-color: transparent transparent transparent var(--SubPager-tab-bg);
  transition: all 0.1s;
}

.SubPager-tab-active {
  z-index: 10;
  pointer-events: none;
}
.SubPager-tab-active a {
  color: var(--SubPager-tab-active-fg);
  background-color: var(--SubPager-tab-active-bg);
}
.SubPager-tab-active a::before {
  border-color: transparent transparent var(--SubPager-tab-active-bg) transparent;
}
.SubPager-tab-active a::after {
  border-color: transparent transparent transparent var(--SubPager-tab-active-bg);
}

.SubPager-tab a:hover {
  text-decoration: none;
  background-color: var(--SubPager-tab-hover-bg);
  color: var(--SubPager-tab-hover-fg);
}
.SubPager-tab a:hover::before {
  border-color: transparent transparent var(--SubPager-tab-hover-bg) transparent;
}
.SubPager-tab a:hover::after {
  border-color: transparent transparent transparent var(--SubPager-tab-hover-bg);
}
.DevIconsPage-content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 110px);
}
.DevIconsPage-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}
.DevIconsPage-icon svg {
  font-size: 40px;
  margin: 5px;
}
.DevIconsPage-icon h5 {
  margin: 10px 0 0 0;
  font-size: 15px;
  word-break: break-word;
}
.DevIconsPage-icon h6 {
  font-family: var(--font-family-monospace);
  margin: 0;
  font-size: 12px;
}
.DevIconsPage-icon a {
  color: var(--primary);
}

.AjaxWrapper-waiting {
  margin: 10vmin;
  display: flex;
  justify-content: center;
}
.AjaxWrapper-waiting > .Spinner {
  width: 30vmin;
  height: 30vmin;
}
.AjaxWrapper-error {
  margin: 20px;
  display: flex;
  justify-content: center;
}
.AjaxWrapper-error > * {
  max-width: var(--breakpoint-md);
}

.Spinner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  width: 30px;
  height: 30px;
  grid-column-gap: 2%;
  grid-row-gap: 2%;
  align-self: center;
  justify-self: center;
}
.Spinner > div {
  background: transparent;
  animation: SpinnerColor 2s infinite alternate;
}
.Spinner > div:nth-child(1) {
  animation-delay: 0.2000004s;
}
.Spinner > div:nth-child(2) {
  animation-delay: 0.1000001s;
}
.Spinner > div:nth-child(4) {
  animation-delay: 0.3000004s;
}
.Spinner > div:nth-child(5) {
  grid-column: 2 / span 2;
  animation-delay: 0.7000001s;
}
.Spinner > div:nth-child(6) {
  clear: both;
  animation-delay: 0.4000004s;
}
.Spinner > div:nth-child(7) {
  animation-delay: 0.5000001s;
}
.Spinner > div:nth-child(8) {
  animation-delay: 0.6000004s;
}


@keyframes SpinnerColor {
  0% {
    background: #06ebb3;
  }
  25% {
    background: #05ae84;
  }
  50% {
    background: #06c697;
  }
}
.GridLayout {
  display: grid;
}
.GridLayout-auto-cols {
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}
.GridLayout-auto-rows {
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
}

@media (max-width: 767.98px) {
  .GridLayout-responsive {
    display: block;
  }
}

/* .ActivityInternalsPage {
  padding: 40px;
}

.ActivityInternalsPage .ToolBar-strip {
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
} */

.Expander {
  margin-bottom: 15px;
}
.Expander-title {
  background: rgba(185, 198, 201, 0.5);
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr auto;
  cursor: pointer;
  transition: all 0.1s;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: rgb(78, 89, 91);
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Expander-title:hover {
  background: rgba(185, 198, 201, 0.4);
}

.Expander-content {
  padding: 10px;
  background-color: rgba(230, 240, 242, 0.3);
  border: 1px solid rgba(185, 198, 201, 0.5);
  border-top: none;
}

.Expander-danger .Expander-title {
  background: rgb(231, 76, 60);
  color: white;
}
.Expander-danger .Expander-title:hover {
  background: rgba(231, 76, 60, 0.9);
}
.Expander-danger .Expander-content {
  background: rgba(231, 76, 60, 0.2);
}
:root {
  --ExpanderGroup-color: #468074;
}

.ExpanderGroup {
  border-top: 1px solid var(--ExpanderGroup-color);
  padding: 15px 20px 30px 20px;
}

.ExpanderGroup > legend {
  font-size: 1.1em;
  color: var(--ExpanderGroup-color);
  text-align: left;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
  cursor: default;
}

.ExpanderGroup-content {
  margin: -10px;
}

.SelectionSidebar {
  width: var(--sidebar-width);
}

.SelectionSidebar-content {
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
  width: var(--sidebar-width);
  margin-bottom: 1rem;
}

.SelectionSidebar-no-data {
  border: 2px dotted rgba(0, 0, 0, 0.1);
  padding: 2rem 1.5rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 1.171875rem;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 15px;
}

.SidebarLayout .ToolBar {
  max-width: calc(100vw - var(--sidebar-width) - var(--sidebar-gap) - var(--sidebar-gap) - 10px);
}
.ObjectInfo-prop {
  margin-bottom: 10px;
}

.ObjectInfo-key {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  line-height: 16px;
}
.ObjectInfo-key > span {
  font-size: 11px;
  line-height: 11px;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  padding: 2px 10px;
  border-radius: 2px 2px 0 0;
}

.ObjectInfo-value {
  padding-left: 10px;
  word-break: break-all;
}

/* Nesting support */

.ObjectInfo .ObjectInfo {
  margin-top: 5px;
}
.ObjectInfo .ObjectInfo .ObjectInfo-key {
  border: none;
}
.ObjectInfo .ObjectInfo .ObjectInfo-key > span {
  font-size: 10px;
  background: transparent;
  padding: 0;
  border-radius: 0;
}

.CodeBlock {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.NumericalValue {
  font-family: var(--font-family-monospace);
  font-size: 0.9em; /* Slightly smaller, feels better */
}

.DataTable {
  width: auto;
  width: initial;
  border: none;
  position: relative;
  z-index: 0;
}
.DataTable thead,
.DataTable tbody,
.DataTable tr,
.DataTable th,
.DataTable td {
  position: relative !important;
}

.DataTable-sorting-pip {
  color: #999;
  position: absolute;
  right: 0;
  width: 15px;
}

.DataTable th {
  padding-left: 5px;
  padding-right: 20px;
  position: relative;
}

.DataTable th a {
  color: #333;
}
.DataTable th a:hover,
.DataTable th a:hover .DataTable-sorting-pip {
  color: var(--green);
}

.DataTable-th-current {
  background-color: var(--green) !important;
}
.DataTable-th-current a,
.DataTable-th-current .DataTable-sorting-pip {
  color: white !important;
}

.DataTable-th-unsortable {
  color: #666;
  cursor: default;
  padding-right: 5px !important;
}

.DataTable-no-data {
  font-size: 30px;
  padding: 50px !important;
  color: rgba(0,0,0,0.5);
  text-align: center;
}

.DataTable-no-data-reason {
  font-size: 20px;
  color: rgba(0,0,0,0.3);
}

/* Selection */

.DataTable-selectable tbody tr:hover {
  background: rgba(0,0,0,0.05);
  cursor: default;
}
.DataTable-selectable tbody tr.DataTable-row-selected {
  background: #cfe6e0;
}
.DataTable-selectable tbody tr.DataTable-row-selected:hover {
  background: #add9cd;
}
.DataTable-selectable tbody tr.DataTable-row-selected td {
  border-color: #b8d9cf;
}

/* Groups */

:root {
  --DataTable-group-title: #797979;
  --DataTable-group-bg: #e5e5e5;
  --DataTable-group-edge: #d9d9d9;
}

.DataTable-group-headers {
}
.DataTable-group-th-empty,
.DataTable-group-th {
  padding: 0 10px !important;
  z-index: 1;
}
.DataTable-group-th-empty {
  border: none !important;
}
.DataTable-group-th {
  border-left: 2px solid var(--DataTable-group-edge) !important;
  border-right: 2px solid var(--DataTable-group-edge) !important;
  border-top: 2px solid var(--DataTable-group-edge) !important;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  background-color: var(--DataTable-group-bg);
  color: var(--DataTable-group-title);
  cursor: default;
}

.DataTable-group-td-start,
.DataTable-group-th-start {
  border-left: 2px solid var(--DataTable-group-edge) !important;
}
.DataTable-group-td-end,
.DataTable-group-th-end {
  border-right: 2px solid var(--DataTable-group-edge) !important;
}

/* Highlighting */

.DataTable-highlight {
  background-color: rgba(232, 245, 252, 0.6);
}
.DataTable-row-selected .DataTable-highlight {
  background-color: rgba(212, 235, 250, 0.6);
}
.DataTable tbody tr:hover .DataTable-highlight {
  background-color: rgba(225, 240, 250, 0.6);
}
.DataTable tbody tr.DataTable-row-selected:hover .DataTable-highlight {
  background-color: rgba(206, 230, 245, 0.6);
}
.DataTable-group-th.DataTable-highlight {
  background-color: rgba(218, 225, 230, 0.6);
}
.Pagination .page-item {
  min-width: 45px;
  text-align: center;
}
.Card {
  margin-bottom: 20px;
  width: 100%;
}

.Card-danger > .card > .card-header {
  background: var(--danger);
  color: white;
}

@media (min-width: 576px) {
  .Card {
    display: inline-block;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
    margin-right: 20px;
  }
}
.IconButton-disabled {
  pointer-events: all !important;
  cursor: default;
  box-shadow: none !important;
}

.ExchangeInternalsPage-audit-result {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-top: 1.25rem;
}
.ExchangeInternalsPage-audit-result > *:not(:first-child) {
  margin-left: 30px;
}

.LoggerInternalsPage-collectors .card-body {
  padding: 0;
}
.LoggerInternalsPage-collectors .DataTable {
  border: none;
  margin: 0 -1px -1px -1px;
}

.f16 .flag{display:inline-block;height:16px;width:16px;vertical-align:text-top;line-height:16px;background:url(/static/media/flags16.109d8321.png) no-repeat;}
.f16 ._African_Union{background-position:0 -16px;}
.f16 ._Arab_League{background-position:0 -32px;}
.f16 ._ASEAN{background-position:0 -48px;}
.f16 ._CARICOM{background-position:0 -64px;}
.f16 ._CIS{background-position:0 -80px;}
.f16 ._Commonwealth{background-position:0 -96px;}
.f16 ._England{background-position:0 -112px;}
.f16 ._European_Union, .f16 .eu{background-position:0 -128px;}
.f16 ._Islamic_Conference{background-position:0 -144px;}
.f16 ._Kosovo{background-position:0 -160px;}
.f16 ._NATO{background-position:0 -176px;}
.f16 ._Northern_Cyprus{background-position:0 -192px;}
.f16 ._Northern_Ireland{background-position:0 -208px;}
.f16 ._Olimpic_Movement{background-position:0 -224px;}
.f16 ._OPEC{background-position:0 -240px;}
.f16 ._Red_Cross{background-position:0 -256px;}
.f16 ._Scotland{background-position:0 -272px;}
.f16 ._Somaliland{background-position:0 -288px;}
.f16 ._Tibet{background-position:0 -304px;}
.f16 ._United_Nations{background-position:0 -320px;}
.f16 ._Wales{background-position:0 -336px;}
.f16 .ad{background-position:0 -352px;}
.f16 .ae{background-position:0 -368px;}
.f16 .af{background-position:0 -384px;}
.f16 .ag{background-position:0 -400px;}
.f16 .ai{background-position:0 -416px;}
.f16 .al{background-position:0 -432px;}
.f16 .am{background-position:0 -448px;}
.f16 .ao{background-position:0 -464px;}
.f16 .aq{background-position:0 -480px;}
.f16 .ar{background-position:0 -496px;}
.f16 .as{background-position:0 -512px;}
.f16 .at{background-position:0 -528px;}
.f16 .au{background-position:0 -544px;}
.f16 .aw{background-position:0 -560px;}
.f16 .ax{background-position:0 -576px;}
.f16 .az{background-position:0 -592px;}
.f16 .ba{background-position:0 -608px;}
.f16 .bb{background-position:0 -624px;}
.f16 .bd{background-position:0 -640px;}
.f16 .be{background-position:0 -656px;}
.f16 .bf{background-position:0 -672px;}
.f16 .bg{background-position:0 -688px;}
.f16 .bh{background-position:0 -704px;}
.f16 .bi{background-position:0 -720px;}
.f16 .bj{background-position:0 -736px;}
.f16 .bm{background-position:0 -752px;}
.f16 .bn{background-position:0 -768px;}
.f16 .bo{background-position:0 -784px;}
.f16 .br{background-position:0 -800px;}
.f16 .bs{background-position:0 -816px;}
.f16 .bt{background-position:0 -832px;}
.f16 .bw{background-position:0 -848px;}
.f16 .by{background-position:0 -864px;}
.f16 .bz{background-position:0 -880px;}
.f16 .ca{background-position:0 -896px;}
.f16 .cg{background-position:0 -912px;}
.f16 .cf{background-position:0 -928px;}
.f16 .cd{background-position:0 -944px;}
.f16 .ch{background-position:0 -960px;}
.f16 .ci{background-position:0 -976px;}
.f16 .ck{background-position:0 -992px;}
.f16 .cl{background-position:0 -1008px;}
.f16 .cm{background-position:0 -1024px;}
.f16 .cn{background-position:0 -1040px;}
.f16 .co{background-position:0 -1056px;}
.f16 .cr{background-position:0 -1072px;}
.f16 .cu{background-position:0 -1088px;}
.f16 .cv{background-position:0 -1104px;}
.f16 .cy{background-position:0 -1120px;}
.f16 .cz{background-position:0 -1136px;}
.f16 .de{background-position:0 -1152px;}
.f16 .dj{background-position:0 -1168px;}
.f16 .dk{background-position:0 -1184px;}
.f16 .dm{background-position:0 -1200px;}
.f16 .do{background-position:0 -1216px;}
.f16 .dz{background-position:0 -1232px;}
.f16 .ec{background-position:0 -1248px;}
.f16 .ee{background-position:0 -1264px;}
.f16 .eg{background-position:0 -1280px;}
.f16 .eh{background-position:0 -1296px;}
.f16 .er{background-position:0 -1312px;}
.f16 .es{background-position:0 -1328px;}
.f16 .et{background-position:0 -1344px;}
.f16 .fi{background-position:0 -1360px;}
.f16 .fj{background-position:0 -1376px;}
.f16 .fm{background-position:0 -1392px;}
.f16 .fo{background-position:0 -1408px;}
.f16 .fr{background-position:0 -1424px;}  .f16 .bl, .f16 .cp, .f16 .mf, .f16 .yt{background-position:0 -1424px;}
.f16 .ga{background-position:0 -1440px;}
.f16 .gb{background-position:0 -1456px;}  .f16 .sh{background-position:0 -1456px;}
.f16 .gd{background-position:0 -1472px;}
.f16 .ge{background-position:0 -1488px;}
.f16 .gg{background-position:0 -1504px;}
.f16 .gh{background-position:0 -1520px;}
.f16 .gi{background-position:0 -1536px;}
.f16 .gl{background-position:0 -1552px;}
.f16 .gm{background-position:0 -1568px;}
.f16 .gn{background-position:0 -1584px;}
.f16 .gp{background-position:0 -1600px;}
.f16 .gq{background-position:0 -1616px;}
.f16 .gr{background-position:0 -1632px;}
.f16 .gt{background-position:0 -1648px;}
.f16 .gu{background-position:0 -1664px;}
.f16 .gw{background-position:0 -1680px;}
.f16 .gy{background-position:0 -1696px;}
.f16 .hk{background-position:0 -1712px;}
.f16 .hn{background-position:0 -1728px;}
.f16 .hr{background-position:0 -1744px;}
.f16 .ht{background-position:0 -1760px;}
.f16 .hu{background-position:0 -1776px;}
.f16 .id{background-position:0 -1792px;}
.f16 .mc{background-position:0 -1792px;}
.f16 .ie{background-position:0 -1808px;}
.f16 .il{background-position:0 -1824px;}
.f16 .im{background-position:0 -1840px;}
.f16 .in{background-position:0 -1856px;}
.f16 .iq{background-position:0 -1872px;}
.f16 .ir{background-position:0 -1888px;}
.f16 .is{background-position:0 -1904px;}
.f16 .it{background-position:0 -1920px;}
.f16 .je{background-position:0 -1936px;}
.f16 .jm{background-position:0 -1952px;}
.f16 .jo{background-position:0 -1968px;}
.f16 .jp{background-position:0 -1984px;}
.f16 .ke{background-position:0 -2000px;}
.f16 .kg{background-position:0 -2016px;}
.f16 .kh{background-position:0 -2032px;}
.f16 .ki{background-position:0 -2048px;}
.f16 .km{background-position:0 -2064px;}
.f16 .kn{background-position:0 -2080px;}
.f16 .kp{background-position:0 -2096px;}
.f16 .kr{background-position:0 -2112px;}
.f16 .kw{background-position:0 -2128px;}
.f16 .ky{background-position:0 -2144px;}
.f16 .kz{background-position:0 -2160px;}
.f16 .la{background-position:0 -2176px;}
.f16 .lb{background-position:0 -2192px;}
.f16 .lc{background-position:0 -2208px;}
.f16 .li{background-position:0 -2224px;}
.f16 .lk{background-position:0 -2240px;}
.f16 .lr{background-position:0 -2256px;}
.f16 .ls{background-position:0 -2272px;}
.f16 .lt{background-position:0 -2288px;}
.f16 .lu{background-position:0 -2304px;}
.f16 .lv{background-position:0 -2320px;}
.f16 .ly{background-position:0 -2336px;}
.f16 .ma{background-position:0 -2352px;}
.f16 .md{background-position:0 -2368px;}
.f16 .me{background-position:0 -2384px;}
.f16 .mg{background-position:0 -2400px;}
.f16 .mh{background-position:0 -2416px;}
.f16 .mk{background-position:0 -2432px;}
.f16 .ml{background-position:0 -2448px;}
.f16 .mm{background-position:0 -2464px;}
.f16 .mn{background-position:0 -2480px;}
.f16 .mo{background-position:0 -2496px;}
.f16 .mq{background-position:0 -2512px;}
.f16 .mr{background-position:0 -2528px;}
.f16 .ms{background-position:0 -2544px;}
.f16 .mt{background-position:0 -2560px;}
.f16 .mu{background-position:0 -2576px;}
.f16 .mv{background-position:0 -2592px;}
.f16 .mw{background-position:0 -2608px;}
.f16 .mx{background-position:0 -2624px;}
.f16 .my{background-position:0 -2640px;}
.f16 .mz{background-position:0 -2656px;}
.f16 .na{background-position:0 -2672px;}
.f16 .nc{background-position:0 -2688px;}
.f16 .ne{background-position:0 -2704px;}
.f16 .ng{background-position:0 -2720px;}
.f16 .ni{background-position:0 -2736px;}
.f16 .nl{background-position:0 -2752px;}  .f16 .bq{background-position:0 -2752px;}
.f16 .no{background-position:0 -2768px;}  .f16 .bv, .f16 .nq, .f16 .sj{background-position:0 -2768px;}
.f16 .np{background-position:0 -2784px;}
.f16 .nr{background-position:0 -2800px;}
.f16 .nz{background-position:0 -2816px;}
.f16 .om{background-position:0 -2832px;}
.f16 .pa{background-position:0 -2848px;}
.f16 .pe{background-position:0 -2864px;}
.f16 .pf{background-position:0 -2880px;}
.f16 .pg{background-position:0 -2896px;}
.f16 .ph{background-position:0 -2912px;}
.f16 .pk{background-position:0 -2928px;}
.f16 .pl{background-position:0 -2944px;}
.f16 .pr{background-position:0 -2960px;}
.f16 .ps{background-position:0 -2976px;}
.f16 .pt{background-position:0 -2992px;}
.f16 .pw{background-position:0 -3008px;}
.f16 .py{background-position:0 -3024px;}
.f16 .qa{background-position:0 -3040px;}
.f16 .re{background-position:0 -3056px;}
.f16 .ro{background-position:0 -3072px;}
.f16 .rs{background-position:0 -3088px;}
.f16 .ru{background-position:0 -3104px;}
.f16 .rw{background-position:0 -3120px;}
.f16 .sa{background-position:0 -3136px;}
.f16 .sb{background-position:0 -3152px;}
.f16 .sc{background-position:0 -3168px;}
.f16 .sd{background-position:0 -3184px;}
.f16 .se{background-position:0 -3200px;}
.f16 .sg{background-position:0 -3216px;}
.f16 .si{background-position:0 -3232px;}
.f16 .sk{background-position:0 -3248px;}
.f16 .sl{background-position:0 -3264px;}
.f16 .sm{background-position:0 -3280px;}
.f16 .sn{background-position:0 -3296px;}
.f16 .so{background-position:0 -3312px;}
.f16 .sr{background-position:0 -3328px;}
.f16 .st{background-position:0 -3344px;}
.f16 .sv{background-position:0 -3360px;}
.f16 .sy{background-position:0 -3376px;}
.f16 .sz{background-position:0 -3392px;}
.f16 .tc{background-position:0 -3408px;}
.f16 .td{background-position:0 -3424px;}
.f16 .tg{background-position:0 -3440px;}
.f16 .th{background-position:0 -3456px;}
.f16 .tj{background-position:0 -3472px;}
.f16 .tl{background-position:0 -3488px;}
.f16 .tm{background-position:0 -3504px;}
.f16 .tn{background-position:0 -3520px;}
.f16 .to{background-position:0 -3536px;}
.f16 .tr{background-position:0 -3552px;}
.f16 .tt{background-position:0 -3568px;}
.f16 .tv{background-position:0 -3584px;}
.f16 .tw{background-position:0 -3600px;}
.f16 .tz{background-position:0 -3616px;}
.f16 .ua{background-position:0 -3632px;}
.f16 .ug{background-position:0 -3648px;}
.f16 .us{background-position:0 -3664px;}
.f16 .uy{background-position:0 -3680px;}
.f16 .uz{background-position:0 -3696px;}
.f16 .va{background-position:0 -3712px;}
.f16 .vc{background-position:0 -3728px;}
.f16 .ve{background-position:0 -3744px;}
.f16 .vg{background-position:0 -3760px;}
.f16 .vi{background-position:0 -3776px;}
.f16 .vn{background-position:0 -3792px;}
.f16 .vu{background-position:0 -3808px;}
.f16 .ws{background-position:0 -3824px;}
.f16 .ye{background-position:0 -3840px;}
.f16 .za{background-position:0 -3856px;}
.f16 .zm{background-position:0 -3872px;}
.f16 .zw{background-position:0 -3888px;}
.f16 .sx{background-position:0 -3904px;}
.f16 .cw{background-position:0 -3920px;}
.f16 .ss{background-position:0 -3936px;}
.f16 .nu{background-position:0 -3952px;}

.f32 .flag{display:inline-block;height:32px;width:32px;vertical-align:text-top;line-height:32px;background:url(/static/media/flags32.2e6ce38f.png) no-repeat;}
.f32 ._African_Union{background-position:0 -32px;}
.f32 ._Arab_League{background-position:0 -64px;}
.f32 ._ASEAN{background-position:0 -96px;}
.f32 ._CARICOM{background-position:0 -128px;}
.f32 ._CIS{background-position:0 -160px;}
.f32 ._Commonwealth{background-position:0 -192px;}
.f32 ._England{background-position:0 -224px;}
.f32 ._European_Union, .f32 .eu{background-position:0 -256px;}
.f32 ._Islamic_Conference{background-position:0 -288px;}
.f32 ._Kosovo{background-position:0 -320px;}
.f32 ._NATO{background-position:0 -352px;}
.f32 ._Northern_Cyprus{background-position:0 -384px;}
.f32 ._Northern_Ireland{background-position:0 -416px;}
.f32 ._Olimpic_Movement{background-position:0 -448px;}
.f32 ._OPEC{background-position:0 -480px;}
.f32 ._Red_Cross{background-position:0 -512px;}
.f32 ._Scotland{background-position:0 -544px;}
.f32 ._Somaliland{background-position:0 -576px;}
.f32 ._Tibet{background-position:0 -608px;}
.f32 ._United_Nations{background-position:0 -640px;}
.f32 ._Wales{background-position:0 -672px;}
.f32 .ad{background-position:0 -704px;}
.f32 .ae{background-position:0 -736px;}
.f32 .af{background-position:0 -768px;}
.f32 .ag{background-position:0 -800px;}
.f32 .ai{background-position:0 -832px;}
.f32 .al{background-position:0 -864px;}
.f32 .am{background-position:0 -896px;}
.f32 .ao{background-position:0 -928px;}
.f32 .aq{background-position:0 -960px;}
.f32 .ar{background-position:0 -992px;}
.f32 .as{background-position:0 -1024px;}
.f32 .at{background-position:0 -1056px;}
.f32 .au{background-position:0 -1088px;}
.f32 .aw{background-position:0 -1120px;}
.f32 .ax{background-position:0 -1152px;}
.f32 .az{background-position:0 -1184px;}
.f32 .ba{background-position:0 -1216px;}
.f32 .bb{background-position:0 -1248px;}
.f32 .bd{background-position:0 -1280px;}
.f32 .be{background-position:0 -1312px;}
.f32 .bf{background-position:0 -1344px;}
.f32 .bg{background-position:0 -1376px;}
.f32 .bh{background-position:0 -1408px;}
.f32 .bi{background-position:0 -1440px;}
.f32 .bj{background-position:0 -1472px;}
.f32 .bm{background-position:0 -1504px;}
.f32 .bn{background-position:0 -1536px;}
.f32 .bo{background-position:0 -1568px;}
.f32 .br{background-position:0 -1600px;}
.f32 .bs{background-position:0 -1632px;}
.f32 .bt{background-position:0 -1664px;}
.f32 .bw{background-position:0 -1696px;}
.f32 .by{background-position:0 -1728px;}
.f32 .bz{background-position:0 -1760px;}
.f32 .ca{background-position:0 -1792px;}
.f32 .cd{background-position:0 -1824px;}
.f32 .cf{background-position:0 -1856px;}
.f32 .cg{background-position:0 -1888px;}
.f32 .ch{background-position:0 -1920px;}
.f32 .ci{background-position:0 -1952px;}
.f32 .ck{background-position:0 -1984px;}
.f32 .cl{background-position:0 -2016px;}
.f32 .cm{background-position:0 -2048px;}
.f32 .cn{background-position:0 -2080px;}
.f32 .co{background-position:0 -2112px;}
.f32 .cr{background-position:0 -2144px;}
.f32 .cu{background-position:0 -2176px;}
.f32 .cv{background-position:0 -2208px;}
.f32 .cy{background-position:0 -2240px;}
.f32 .cz{background-position:0 -2272px;}
.f32 .de{background-position:0 -2304px;}
.f32 .dj{background-position:0 -2336px;}
.f32 .dk{background-position:0 -2368px;}
.f32 .dm{background-position:0 -2400px;}
.f32 .do{background-position:0 -2432px;}
.f32 .dz{background-position:0 -2464px;}
.f32 .ec{background-position:0 -2496px;}
.f32 .ee{background-position:0 -2528px;}
.f32 .eg{background-position:0 -2560px;}
.f32 .eh{background-position:0 -2592px;}
.f32 .er{background-position:0 -2624px;}
.f32 .es{background-position:0 -2656px;}
.f32 .et{background-position:0 -2688px;}
.f32 .fi{background-position:0 -2720px;}
.f32 .fj{background-position:0 -2752px;}
.f32 .fm{background-position:0 -2784px;}
.f32 .fo{background-position:0 -2816px;}
.f32 .fr{background-position:0 -2848px;}  .f32 .bl, .f32 .cp, .f32 .mf, .f32 .yt{background-position:0 -2848px;}
.f32 .ga{background-position:0 -2880px;}
.f32 .gb{background-position:0 -2912px;}  .f32 .sh{background-position:0 -2912px;}
.f32 .gd{background-position:0 -2944px;}
.f32 .ge{background-position:0 -2976px;}
.f32 .gg{background-position:0 -3008px;}
.f32 .gh{background-position:0 -3040px;}
.f32 .gi{background-position:0 -3072px;}
.f32 .gl{background-position:0 -3104px;}
.f32 .gm{background-position:0 -3136px;}
.f32 .gn{background-position:0 -3168px;}
.f32 .gp{background-position:0 -3200px;}
.f32 .gq{background-position:0 -3232px;}
.f32 .gr{background-position:0 -3264px;}
.f32 .gt{background-position:0 -3296px;}
.f32 .gu{background-position:0 -3328px;}
.f32 .gw{background-position:0 -3360px;}
.f32 .gy{background-position:0 -3392px;}
.f32 .hk{background-position:0 -3424px;}
.f32 .hn{background-position:0 -3456px;}
.f32 .hr{background-position:0 -3488px;}
.f32 .ht{background-position:0 -3520px;}
.f32 .hu{background-position:0 -3552px;}
.f32 .id{background-position:0 -3584px;}
.f32 .mc{background-position:0 -3584px;}
.f32 .ie{background-position:0 -3616px;}
.f32 .il{background-position:0 -3648px;}
.f32 .im{background-position:0 -3680px;}
.f32 .in{background-position:0 -3712px;}
.f32 .iq{background-position:0 -3744px;}
.f32 .ir{background-position:0 -3776px;}
.f32 .is{background-position:0 -3808px;}
.f32 .it{background-position:0 -3840px;}
.f32 .je{background-position:0 -3872px;}
.f32 .jm{background-position:0 -3904px;}
.f32 .jo{background-position:0 -3936px;}
.f32 .jp{background-position:0 -3968px;}
.f32 .ke{background-position:0 -4000px;}
.f32 .kg{background-position:0 -4032px;}
.f32 .kh{background-position:0 -4064px;}
.f32 .ki{background-position:0 -4096px;}
.f32 .km{background-position:0 -4128px;}
.f32 .kn{background-position:0 -4160px;}
.f32 .kp{background-position:0 -4192px;}
.f32 .kr{background-position:0 -4224px;}
.f32 .kw{background-position:0 -4256px;}
.f32 .ky{background-position:0 -4288px;}
.f32 .kz{background-position:0 -4320px;}
.f32 .la{background-position:0 -4352px;}
.f32 .lb{background-position:0 -4384px;}
.f32 .lc{background-position:0 -4416px;}
.f32 .li{background-position:0 -4448px;}
.f32 .lk{background-position:0 -4480px;}
.f32 .lr{background-position:0 -4512px;}
.f32 .ls{background-position:0 -4544px;}
.f32 .lt{background-position:0 -4576px;}
.f32 .lu{background-position:0 -4608px;}
.f32 .lv{background-position:0 -4640px;}
.f32 .ly{background-position:0 -4672px;}
.f32 .ma{background-position:0 -4704px;}
.f32 .md{background-position:0 -4736px;}
.f32 .me{background-position:0 -4768px;}
.f32 .mg{background-position:0 -4800px;}
.f32 .mh{background-position:0 -4832px;}
.f32 .mk{background-position:0 -4864px;}
.f32 .ml{background-position:0 -4896px;}
.f32 .mm{background-position:0 -4928px;}
.f32 .mn{background-position:0 -4960px;}
.f32 .mo{background-position:0 -4992px;}
.f32 .mq{background-position:0 -5024px;}
.f32 .mr{background-position:0 -5056px;}
.f32 .ms{background-position:0 -5088px;}
.f32 .mt{background-position:0 -5120px;}
.f32 .mu{background-position:0 -5152px;}
.f32 .mv{background-position:0 -5184px;}
.f32 .mw{background-position:0 -5216px;}
.f32 .mx{background-position:0 -5248px;}
.f32 .my{background-position:0 -5280px;}
.f32 .mz{background-position:0 -5312px;}
.f32 .na{background-position:0 -5344px;}
.f32 .nc{background-position:0 -5376px;}
.f32 .ne{background-position:0 -5408px;}
.f32 .ng{background-position:0 -5440px;}
.f32 .ni{background-position:0 -5472px;}
.f32 .nl{background-position:0 -5504px;}  .f32 .bq{background-position:0 -5504px;}
.f32 .no{background-position:0 -5536px;}  .f32 .bv, .f32 .nq, .f32 .sj{background-position:0 -5536px;}
.f32 .np{background-position:0 -5568px;}
.f32 .nr{background-position:0 -5600px;}
.f32 .nz{background-position:0 -5632px;}
.f32 .om{background-position:0 -5664px;}
.f32 .pa{background-position:0 -5696px;}
.f32 .pe{background-position:0 -5728px;}
.f32 .pf{background-position:0 -5760px;}
.f32 .pg{background-position:0 -5792px;}
.f32 .ph{background-position:0 -5824px;}
.f32 .pk{background-position:0 -5856px;}
.f32 .pl{background-position:0 -5888px;}
.f32 .pr{background-position:0 -5920px;}
.f32 .ps{background-position:0 -5952px;}
.f32 .pt{background-position:0 -5984px;}
.f32 .pw{background-position:0 -6016px;}
.f32 .py{background-position:0 -6048px;}
.f32 .qa{background-position:0 -6080px;}
.f32 .re{background-position:0 -6112px;}
.f32 .ro{background-position:0 -6144px;}
.f32 .rs{background-position:0 -6176px;}
.f32 .ru{background-position:0 -6208px;}
.f32 .rw{background-position:0 -6240px;}
.f32 .sa{background-position:0 -6272px;}
.f32 .sb{background-position:0 -6304px;}
.f32 .sc{background-position:0 -6336px;}
.f32 .sd{background-position:0 -6368px;}
.f32 .se{background-position:0 -6400px;}
.f32 .sg{background-position:0 -6432px;}
.f32 .si{background-position:0 -6464px;}
.f32 .sk{background-position:0 -6496px;}
.f32 .sl{background-position:0 -6528px;}
.f32 .sm{background-position:0 -6560px;}
.f32 .sn{background-position:0 -6592px;}
.f32 .so{background-position:0 -6624px;}
.f32 .sr{background-position:0 -6656px;}
.f32 .st{background-position:0 -6688px;}
.f32 .sv{background-position:0 -6720px;}
.f32 .sy{background-position:0 -6752px;}
.f32 .sz{background-position:0 -6784px;}
.f32 .tc{background-position:0 -6816px;}
.f32 .td{background-position:0 -6848px;}
.f32 .tg{background-position:0 -6880px;}
.f32 .th{background-position:0 -6912px;}
.f32 .tj{background-position:0 -6944px;}
.f32 .tl{background-position:0 -6976px;}
.f32 .tm{background-position:0 -7008px;}
.f32 .tn{background-position:0 -7040px;}
.f32 .to{background-position:0 -7072px;}
.f32 .tr{background-position:0 -7104px;}
.f32 .tt{background-position:0 -7136px;}
.f32 .tv{background-position:0 -7168px;}
.f32 .tw{background-position:0 -7200px;}
.f32 .tz{background-position:0 -7232px;}
.f32 .ua{background-position:0 -7264px;}
.f32 .ug{background-position:0 -7296px;}
.f32 .us{background-position:0 -7328px;}
.f32 .uy{background-position:0 -7360px;}
.f32 .uz{background-position:0 -7392px;}
.f32 .va{background-position:0 -7424px;}
.f32 .vc{background-position:0 -7456px;}
.f32 .ve{background-position:0 -7488px;}
.f32 .vg{background-position:0 -7520px;}
.f32 .vi{background-position:0 -7552px;}
.f32 .vn{background-position:0 -7584px;}
.f32 .vu{background-position:0 -7616px;}
.f32 .ws{background-position:0 -7648px;}
.f32 .ye{background-position:0 -7680px;}
.f32 .za{background-position:0 -7712px;}
.f32 .zm{background-position:0 -7744px;}
.f32 .zw{background-position:0 -7776px;}
.f32 .sx{background-position:0 -7808px;}
.f32 .cw{background-position:0 -7840px;}
.f32 .ss{background-position:0 -7872px;}
.f32 .nu{background-position:0 -7904px;}

.CountryFlag {
  vertical-align: middle;
}

.KYCLevelIcon {
  display: inline-block;
  position: relative;
  font-size: inherit;
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}
.KYCLevelIcon > span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  box-shadow: inset -0.02em -0.02em 0.05em 0.01em rgba(0, 0, 0, 0.2);
  font-family: var(--font-family-monospace);
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.7em;
}
.KYCLevelIcon-level-0 > span {
  background: hsl(343, 55%, 71%);
  color: white;
}
.KYCLevelIcon-level-1 > span {
  background: hsl(30, 55%, 70%);
}
.KYCLevelIcon-level-2 > span {
  background: hsl(193, 55%, 75%);
}
.KYCLevelIcon-level-3 > span {
  background: hsl(51, 70%, 70%);
}

.MailerInternalsPage textarea {
  font-family: var(--font-family-monospace);
  height: 200px;
}

@media (min-width: 481px) {
  .MailerInternalsPage-send-form {
    min-width: 480px;
  }
}

@media (min-width: 769px) {
  .MailerInternalsPage-send-form {
    min-width: 768px;
  }
}

.MarketInternalsPage-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.MarketInternalsPage-market {
  border: 1px solid #95a5a6;
  border-radius: 4px;
  overflow: hidden;
}
.MarketInternalsPage-market + .MarketInternalsPage-market {
  margin-top: 20px;
}

.MarketInternalsPage-market-header {
  /*background: linear-gradient(to bottom, rgba(0,0,0,0.08), transparent);*/
  background: #ebeff0;
  padding: 10px;
  min-width: 120px;
  display: grid;
  grid-template-rows: 1fr auto;
}
.MarketInternalsPage-market-header h4 {
  text-align: center;
  color: #4a5d60;
  margin: 0;
}
.MarketInternalsPage-market-controls {
  margin-top: 5px;
  text-align: center;
}
.MarketInternalsPage-market-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  padding: 15px;
}

.MarketInternalsPage-market-col {
  min-width: 200px;
}

@media (min-width: 768px) {
  .MarketInternalsPage-market {
    display: grid;
    grid-template-columns: auto 1fr auto;
  }
}

@media (min-width: 576px) {
  .MarketInternalsPage-market-col:not(:last-child) {
    margin-right: 15px;
  }
}

.WalletInternalsPage .DataTable-td-index-4 {}
:root {
  /* A4 width */
  --KYCLetterPage-width: 595px;
  --KYCLetterPage-padding-x: calc(var(--KYCLetterPage-width) * 0.05);
  --KYCLetterPage-padding-y: calc(var(--KYCLetterPage-width) * 0.2);
}

.KYCLetterPage {
  width: var(--KYCLetterPage-width);
  font-size: 18px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: var(--KYCLetterPage-padding-y) var(--KYCLetterPage-padding-x);
}
.Breadcrumbs {
  padding: 5px 10px;
  background: #22303f;
  color: white;
  font-size: 14px;
}
.MainMenu-navigation {
  margin-left: 20px;
}

.MainMenu-navigation button {
  padding: 0;
}

.MainMenu-navigation div button:nth-child(2) {
  padding: 0 5px;
}

.MainMenu-navigation button:hover,
.MainMenu-navigation button:hover * {
  color: var(--xcalibra) !important;
}

.MainMenu-user {
  margin-left: auto;
  position: -webkit-sticky;
  position: sticky;
  right: 10px;
}

:not(:hover) .MainMenu-active-submenu-item {
  background: var(--success);
  color: white !important;
}

/* Based on how big it actually is */
@media (max-width: 991px) and (min-width: 768px) {
  .MainMenu-item-title {
    display: none;
  }
}

@media (max-width: 1150px) and (min-width: 768px) {
  .MainMenu-user .MainMenu-item-title {
    display: none;
  }
}

.MainMenuSubmenu .dropdown-item {
  padding: 0;
}

.MainMenuSubmenu .dropdown-item > a {
  padding: 0.25rem 1.5rem;
  color: #000;
}

.MainMenuSubmenu .dropdown-item:hover > a {
  color: #fff;
  text-decoration: none;
}

.MainMenuSubmenu .dropdown-menu {
  min-width: 8rem;
  top: -5px !important;
}

.MainMenuSubmenu .MainMenuSubmenu-open > a {
  color: var(--xcalibra) !important;
}

.MainMenuSubmenu .MainMenuSubmenu-active > a {
  color: #18bc9c !important;
}

.BoundConfirmationModal-action-list {
    max-height: 40vh;
    overflow: auto;
  }
.Select {
  z-index: 3;
}
.Select__control {
  color: #7b8a8b;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.Select__control--is-focused {
  color: #7b8a8b;
  background-color: #fff;
  border-color: #597ea2 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25) !important;
}
.Select__option--is-focused {
  background-color: #cfe6e0 !important;
}
.Select__option--is-selected {
  background-color: var(--green) !important;
}
.Select__option.Select__option--is-selected span {
  /* Make sure we override stuff like text-success */
  color: white !important;
}
.Select__option--is-focused.Select__option--is-selected {
  background-color: #19b391 !important;
}
.Select__clear-indicator {
  cursor: pointer;
}

.CriteriaUserPicker .Select {
  min-width: 300px;
}

.CriteriaUserPicker .Select__menu {
  z-index: 100;
}
.Toggler-btn:hover {
  background-color: var(--success);
}
.HomePage-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.HomePage-link:hover {
  text-decoration: none;
}
.HomePage-link h6 {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 992px) {
  .HomePage-section {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .HomePage-section {
    display: block;
  }
  .HomePage-link {
    display: block;
  }
  .HomePage-link + .HomePage-link {
    margin-top: 10px;
  }
}

:root {
  --ConfigDisabledEditor-transition: all 0.3s;
}

.ConfigDisabledEditor-holder {
  background: white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: var(--ConfigDisabledEditor-transition);
}

.ConfigDisabledEditor-holder .ThreeStateCheckbox {
  margin: 2px 4px;
}

.ConfigDisabledEditor-reason {
  transition: var(--ConfigDisabledEditor-transition);
  position: relative;
}

.ConfigDisabledEditor-reason textarea {
  border: none;
  border-radius: 0 0 0.25rem 0.25rem;
  position: relative;
  height: 100%;
  background: transparent;
  overflow: hidden;
}

.ConfigDisabledEditor-reason-label {
  position: absolute;
  right: -1px;
  bottom: -1px;
  z-index: 1;
  border: 1px solid #ced4da;
  background: var(--light);
  padding: 4px 8px;
  color: var(--dark);
  border-radius: 0.25rem 0 0.25rem 0;
  opacity: 1;
  transition: var(--ConfigDisabledEditor-transition);
  pointer-events: none;
}

/* Enabled state, hide stuff */

.ConfigDisabledEditor-enabled .ConfigDisabledEditor-holder {
  background: transparent;
  border-color: transparent;
  overflow: hidden;
}

.ConfigDisabledEditor-enabled .ConfigDisabledEditor-reason {
  height: 0;
  overflow: hidden;
}

.ConfigDisabledEditor-enabled .ConfigDisabledEditor-reason-label {
  opacity: 0;
}

.ConfigDisabledEditor-reason textarea:focus + .ConfigDisabledEditor-reason-label {
  opacity: 0.3;
}
.SettingsManagedConfigsPage .Select {
  z-index: auto !important;
}

.SettingsManagedConfigsPage form {
  max-width: 450px;
}

.SettingsRegistrationPage form > * {
  margin: 10px 20px;
}

.SettingsRegistrationPage form > div > label {
  font-weight: 700;
}

.SettingsRegistrationPage textarea {
  max-width: 600px;
}

.CheckboxGroup {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}

.CheckboxGroup-sub {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.CheckboxGroup-sub > div {
  margin-right: 40px;
}
.CheckboxGroup-sub label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 768px) {
  .CheckboxGroup-sub {
    max-height: 700px;
  }

  .CheckboxGroup-sub > div {
    width: 320px;
  }
}

@media only screen and (min-width: 1084px) {
  .CheckboxGroup-sub {
    max-height: 480px;
  }

  .CheckboxGroup-sub > div {
    width: 320px;
  }
}

@media only screen and (min-width: 1224px) {
  .CheckboxGroup-sub {
    max-height: 420px;
  }

  .CheckboxGroup-sub > div {
    width: 320px;
  }
}

@media only screen and (min-width: 1424px) {
  .CheckboxGroup-sub {
    max-height: 340px;
  }

  .CheckboxGroup-sub > div {
    width: 320px;
  }
}

@media only screen and (min-width: 1824px) {
  .CheckboxGroup-sub {
    max-height: 300px;
  }

  .CheckboxGroup-sub > div {
    width: 320px;
  }
}

@media only screen and (min-width: 1924px) {
  .CheckboxGroup-sub {
    max-height: 280px;
  }
}

.CriteriaInstrumentPicker .Select {
  min-width: 300px;
}

.TradingTradesPage .DataTable-td-field-price {
  padding: 0.5em !important;
  font-size: 0.9em;
}

.TradingTradesPage .DataTable-td-field-timestamp {
  padding: 0.5em !important;
  font-size: 0.9em;
}

.CriteriaOrderTypePicker .Select {
  min-width: 140px;
}

.CriteriaPairPicker .Select {
    min-width: 300px;
  }
.TrollboxMessagesPage-flag-form {
  padding: 5px;
  margin-bottom: 5px;
}

.TrollboxBanWidget-form {
  padding: 5px;
  margin-bottom: 5px;
}

.TrollboxBanWidget-reason-input {
  min-height: 70px;
  max-height: 300px;
}

.TrollboxProfanityPage textarea {
  max-width: 300px;
  min-height: 500px;
}

.TrollboxNicknameWidget-form {
  padding: 5px;
  margin-bottom: 5px;
}

.TrollboxNicknameWidget-reason-input {
  min-height: 70px;
  max-height: 300px;
}

.CustomersPage-labeled-badges {
  font-weight: bold;
}
.CustomersPage-strong-em {
  font-weight: bold;
  font-style: italic;
}

.CustomersPage-audit-modal {
}

.ConstrainedMultilineText {
  white-space: pre-line;
  min-width: 10rem;
  max-height: 10rem;
  overflow-y: auto;
}

.ExternalResourceLink {
  color: var(--gray-dark);
  padding: 4px 9px 2px 2px; /* The thinner top balances it visually better */
}
.ExternalResourceLink-nowrap {
  white-space: nowrap;
}
.ExternalResourceLink > svg + span {
  /* Put spacing between image and label */
  margin-left: 0.25rem;
}

span.ExternalResourceLink {
  cursor: default;
}

a.ExternalResourceLink:hover,
a.ExternalResourceLink:active {
  text-decoration: underline;
  color: var(--success);
}
a.ExternalResourceLink:hover:after,
a.ExternalResourceLink:active:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 0;
  border-color: transparent var(--success) transparent transparent;
  position: absolute;
  margin-left: 1px;
}

.ExternalResourceLink + .ExternalResourceLink {
  margin-left: 3px;
}

.NestedExpandableObjectInfo > div > .Expander-content {
  padding: 0;
}
.NestedExpandableObjectInfo-item > div > .Expander-content {
  border: none;
  background: none;
}
.NestedExpandableObjectInfo-title {
  background: rgba(185, 198, 201, 0.2);
  color: #468074;
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: auto 1fr;
  padding: 3px 3px 3px 5px;
  cursor: pointer;
  transition: all 0.1s;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.NestedExpandableObjectInfo-title:hover {
  background: rgba(185, 198, 201, 0.1);
}

.NestedExpandableObjectInfo > div > .Expander-content > .Expander {
  margin: 0;
}

@media (max-width: 767.98px) {
  .MicropaymentEditPage-reference-and-customer .btn {
    display: block;
    width: 100%;
  }
  .MicropaymentEditPage-reference-and-customer svg {
    display: none;
  }

  .MicropaymentEditPage-documents .text-nowrap {
    white-space: normal !important;
  }
}

.BoundMicropaymentAuditModal {
}

.BoundMicropaymentAuditModal-content {
  border-bottom: 1px solid var(--ExpanderGroup-color);
}
.BoundMicropaymentAuditModal-content .ExpanderGroup {
  position: relative;
  padding-bottom: 10px;
}

.BoundMicropaymentAuditModal-auditor-notes {
  margin: 1rem 0.5rem 0 0.5rem;
}

@media (min-width: 992px) {
  .BoundMicropaymentAuditModal-content .ExpanderGroup:first-child:after {
    content: '';
    position: absolute;
    right: 0;
    top: 11px;
    bottom: 0;
    width: 1px;
    border-right: 1px solid var(--ExpanderGroup-color);
  }
}

@media (max-width: 991.98px) {
  .BoundMicropaymentAuditModal-content {
    display: block;
  }
}

.CriteriaKYCAdminActionPicker .Select {
  min-width: 265px;
}

.CriteriaNationalityPicker .Select {
    min-width: 300px;
  }
.CustomerKYCRequestStatus-other-track {
  opacity: 0.5;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.KYCVerificationStatus-labeled-badges {
  font-weight: bold;
}
.KYCVerificationStatus-fail-list {
  margin: 0;
  padding-left: 1rem;
}

.ExpanderAddOn {
  background: rgba(0, 0, 0, 0.1);
  margin: -10px -10px 10px -10px;
  padding: 2px 10px 10px 10px;
}
.CriticalityIndicator {
  height: 4px;
  border: none;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.4), 0 2px 2px rgba(0,0,0,0.5);
}
.CriticalityIndicator-green {
  background-color: var(--green);
}
.CriticalityIndicator-yellow {
  background-color: var(--yellow);
}
.CriticalityIndicator-red {
  background-color: var(--red);
}
