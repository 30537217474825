.Expander {
  margin-bottom: 15px;
}
.Expander-title {
  background: rgba(185, 198, 201, 0.5);
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr auto;
  cursor: pointer;
  transition: all 0.1s;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: rgb(78, 89, 91);
  align-items: center;
  user-select: none;
}
.Expander-title:hover {
  background: rgba(185, 198, 201, 0.4);
}

.Expander-content {
  padding: 10px;
  background-color: rgba(230, 240, 242, 0.3);
  border: 1px solid rgba(185, 198, 201, 0.5);
  border-top: none;
}

.Expander-danger .Expander-title {
  background: rgb(231, 76, 60);
  color: white;
}
.Expander-danger .Expander-title:hover {
  background: rgba(231, 76, 60, 0.9);
}
.Expander-danger .Expander-content {
  background: rgba(231, 76, 60, 0.2);
}